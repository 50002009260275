import React, { Component } from 'react';
//import ReactDOM from 'react-dom'
import firebase from '../../../firebase/firebase';
// import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
// import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { CSVReader } from 'react-papaparse'
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = (theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
});

class FileUploadButton extends Component {

    constructor(props) {
        super(props);
        this.state = { open: false, snackBarOpen: false };
        //this.onChangeHandler = this.onChangeHandler.bind(this);
        this.handleSnackBarClose = this.handleSnackBarClose.bind(this);
    }

    // async onChangeHandler(event) {
    //     console.log(event.target.files[0]);
    //     this.setState({open: true});
    //     const that = this;
    //     firebase.uploadFile(event.target.files[0]).then(function(result){
    //         result.ref.getDownloadURL().then(function(downloadURL) {
    //             //console.log(downloadURL);
    //             that.setState({open: false});
    //             //that.setState({snackBarOpen: true});
    //             window.location.reload();
                
    //         });
    //     });
    // }

    handleOnDrop = (data, file) => {
        let success = this.processFileData(data);

        if (success) {
            this.setState({open: true, snackBarOpen: true});
            const that = this;
            firebase.uploadFile(file).then(function(result){
                result.ref.getDownloadURL().then(function(downloadURL) {
                    //console.log(downloadURL);
                    that.setState({open: false});
                    //that.setState({snackBarOpen: true});
                    //window.location.reload();
                    
                });
            });
        } else {
            this.setState({open: false});
        }
    }

    processFileData(rows) {
        // Validate
        if (rows.length < 2) {
            alert("Attempting to upload empty file");
            return false; // empty file
        }
        if (rows.length > 5000) {
            alert("File too large.  Please keep file size under 5000 rows");
            return false;
        }

        let x = rows[0].data;
        if (!x.includes('location')) {
            alert("location column is required");
            return false;
        }
        if (!x.includes('machine_id')) {
            alert("machine_id column is required");
            return false;
        }
        if (!x.includes('description')) {
            alert("description column is required");
            return false;
        }

        let locationIndex;
        let machineIdIndex;
        let descriptionIndex;
        let userIndex;
        // let progressiveCountIndex;
        let p1Index, p2Index, p3Index, p4Index, p5Index, p6Index, p7Index, p8Index, p9Index, p10Index;
        let b1Index, b2Index, b3Index, b4Index, b5Index, b6Index, b7Index, b8Index, b9Index, b10Index;
        let i1Index, i2Index, i3Index, i4Index, i5Index, i6Index, i7Index, i8Index, i9Index, i10Index;

        // Required
        locationIndex = x.indexOf('location');
        machineIdIndex = x.indexOf('machine_id');
        descriptionIndex = x.indexOf('description');

        // Optional
        if (x.includes('user')) {
            userIndex = x.indexOf('user');
        }
        // if (x.includes('progressive_count')) {
        //     progressiveCountIndex = x.indexOf('progressive_count');
        // }
        if (x.includes('p_1')) {
            p1Index = x.indexOf('p_1');
        }
        if (x.includes('p_2')) {
            p2Index = x.indexOf('p_2');
        }
        if (x.includes('p_3')) {
            p3Index = x.indexOf('p_3');
        }
        if (x.includes('p_4')) {
            p4Index = x.indexOf('p_4');
        }
        if (x.includes('p_5')) {
            p5Index = x.indexOf('p_5');
        }
        if (x.includes('p_6')) {
            p6Index = x.indexOf('p_6');
        }
        if (x.includes('p_7')) {
            p7Index = x.indexOf('p_7');
        }
        if (x.includes('p_8')) {
            p8Index = x.indexOf('p_8');
        }
        if (x.includes('p_9')) {
            p9Index = x.indexOf('p_9');
        }
        if (x.includes('p_10')) {
            p10Index = x.indexOf('p_10');
        }
        if (x.includes('b_1')) {
            b1Index = x.indexOf('b_1');
        }
        if (x.includes('b_2')) {
            b2Index = x.indexOf('b_2');
        }
        if (x.includes('b_3')) {
            b3Index = x.indexOf('b_3');
        }
        if (x.includes('b_4')) {
            b4Index = x.indexOf('b_4');
        }
        if (x.includes('b_5')) {
            b5Index = x.indexOf('b_5');
        }
        if (x.includes('b_6')) {
            b6Index = x.indexOf('b_6');
        }
        if (x.includes('b_7')) {
            b7Index = x.indexOf('b_7');
        }
        if (x.includes('b_8')) {
            b8Index = x.indexOf('b_8');
        }
        if (x.includes('b_9')) {
            b9Index = x.indexOf('b_9');
        }
        if (x.includes('b_10')) {
            b10Index = x.indexOf('b_10');
        }
        if (x.includes('i_1')) {
            i1Index = x.indexOf('i_1');
        }
        if (x.includes('i_2')) {
            i2Index = x.indexOf('i_2');
        }
        if (x.includes('i_3')) {
            i3Index = x.indexOf('i_3');
        }
        if (x.includes('i_4')) {
            i4Index = x.indexOf('i_4');
        }
        if (x.includes('i_5')) {
            i5Index = x.indexOf('i_5');
        }
        if (x.includes('i_6')) {
            i6Index = x.indexOf('i_6');
        }
        if (x.includes('i_7')) {
            i7Index = x.indexOf('i_7');
        }
        if (x.includes('i_8')) {
            i8Index = x.indexOf('i_8');
        }
        if (x.includes('i_9')) {
            i9Index = x.indexOf('i_9');
        }
        if (x.includes('i_10')) {
            i10Index = x.indexOf('i_10');
        }

        let ret = [];
        for (let i = 1; i < rows.length; i++) {
            let val = rows[i].data;
            if (val.length <= 1) {
                continue;
            }
            let row = {};
            row["machineId"] = val[machineIdIndex];
            row["location"] = val[locationIndex];
            row["description"] = val[descriptionIndex];
            row["fileIndex"] = i;
            //
            if (userIndex !== undefined) { row["user"] = val[userIndex]; }
            //
            let progressiveDescriptionsArray = [];
            if (p1Index !== undefined) { progressiveDescriptionsArray.push(val[p1Index]); }
            if (p2Index !== undefined) { progressiveDescriptionsArray.push(val[p2Index]); }
            if (p3Index !== undefined) { progressiveDescriptionsArray.push(val[p3Index]); }
            if (p4Index !== undefined) { progressiveDescriptionsArray.push(val[p4Index]); }
            if (p5Index !== undefined) { progressiveDescriptionsArray.push(val[p5Index]); }
            if (p6Index !== undefined) { progressiveDescriptionsArray.push(val[p6Index]); }
            if (p7Index !== undefined) { progressiveDescriptionsArray.push(val[p7Index]); }
            if (p8Index !== undefined) { progressiveDescriptionsArray.push(val[p8Index]); }
            if (p9Index !== undefined) { progressiveDescriptionsArray.push(val[p9Index]); }
            if (p10Index !== undefined) { progressiveDescriptionsArray.push(val[p10Index]); }
            //
            let baseValuesArray = [];
            if (b1Index !== undefined) { baseValuesArray.push(val[b1Index]); }
            if (b2Index !== undefined) { baseValuesArray.push(val[b2Index]); }
            if (b3Index !== undefined) { baseValuesArray.push(val[b3Index]); }
            if (b4Index !== undefined) { baseValuesArray.push(val[b4Index]); }
            if (b5Index !== undefined) { baseValuesArray.push(val[b5Index]); }
            if (b6Index !== undefined) { baseValuesArray.push(val[b6Index]); }
            if (b7Index !== undefined) { baseValuesArray.push(val[b7Index]); }
            if (b8Index !== undefined) { baseValuesArray.push(val[b8Index]); }
            if (b9Index !== undefined) { baseValuesArray.push(val[b9Index]); }
            if (b10Index !== undefined) { baseValuesArray.push(val[b10Index]); }
            //
            let incrementValuesArray = [];
            if (i1Index !== undefined) { incrementValuesArray.push(val[i1Index]); }
            if (i2Index !== undefined) { incrementValuesArray.push(val[i2Index]); }
            if (i3Index !== undefined) { incrementValuesArray.push(val[i3Index]); }
            if (i4Index !== undefined) { incrementValuesArray.push(val[i4Index]); }
            if (i5Index !== undefined) { incrementValuesArray.push(val[i5Index]); }
            if (i6Index !== undefined) { incrementValuesArray.push(val[i6Index]); }
            if (i7Index !== undefined) { incrementValuesArray.push(val[i7Index]); }
            if (i8Index !== undefined) { incrementValuesArray.push(val[i8Index]); }
            if (i9Index !== undefined) { incrementValuesArray.push(val[i9Index]); }
            if (i10Index !== undefined) { incrementValuesArray.push(val[i10Index]); }
            row["descriptions"] = progressiveDescriptionsArray;
            row["bases"] = baseValuesArray;
            row["increments"] = incrementValuesArray;
            row["isScanned"] = false;
            ret.push(row);
        }
        firebase.importToDoFileRows(ret);
        return true;
    }

    handleSnackBarClose() {
        this.setState({snackBarOpen: false});
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                {/* <Button variant="contained" color="primary" component="label" startIcon={<CloudUploadIcon />}>
                    Upload<input type="file" name="file" onChange={this.onChangeHandler} style={{ display: "none" }}/>
                </Button> */}
                <Backdrop className={classes.backdrop} open={this.state.open}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <CSVReader
                    onDrop={this.handleOnDrop}
                    //onError={this.handleOnError}
                    noDrag
                    addRemoveButton
                    //onRemoveFile={this.handleOnRemoveFile}
                >
                    <span>Click to upload.</span>
                </CSVReader>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={this.state.snackBarOpen}
                    autoHideDuration={6000}
                    onClose={this.handleSnackBarClose}
                    message="Uploading file.  This may take several minutes."
                    action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleSnackBarClose}>
                        <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                    }
                />
            </div>
        );
    }
}

export default withStyles(styles)(FileUploadButton);