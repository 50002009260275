import React, { useState, useEffect } from 'react';
//import { useState } from 'react';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Hidden, IconButton } from '@material-ui/core';
import { Card } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
//import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
// import Typography from '@material-ui/core/Typography';
import InputIcon from '@material-ui/icons/Input';
import firebase from '../../../../firebase/firebase';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
// import NativeSelect from '@material-ui/core/NativeSelect';


const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  title: {
    color: 'inherit',
    marginLeft: theme.spacing(2),
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  formControl: {
    margin: theme.spacing(0.5),
    minWidth: 120
    },
    appbar: {
        marginLeft: theme.spacing(10),
        flexGrow: 1,
    textAlign: 'center',
      }
}));

//const Topbar = props => {
function Topbar(props) {
  const { className, onSidebarOpen, ...rest } = props;
  const [isAdmin, setIsAdmin] = useState(false);
  const [children, setChildren] = useState([]);

  const classes = useStyles();
  let history = useHistory();

//   let admin = await firebase.isAdminAccount();

  //const [notifications] = useState([]);

  useEffect(() => {
    getIsAdmin();
    getChildren();
  }, []);

  const getIsAdmin = async () => {
      let admin = await firebase.isAdminAccount();
      setIsAdmin(admin);
  };

  const getChildren = async () => {
    let children = await firebase.getChildAccounts();
    setChildren(children);
};

async function handleSignOut() {
    try {
        localStorage.clear();
        await firebase.logout();
        history.replace('/signin');
    } catch (error) {
        alert(error.message);
    }
  };

  function handleCasinoChange(event) {
    localStorage.setItem("childUID", event.target.value);
    window.location.reload();
  }

    const selectItems = children.map((child) => 
        <MenuItem value={child.uid}>{child.name}</MenuItem>
    );

    // const optionItems = children.map((child) => 
    //     <option value={child.uid}>{child.name}</option>
    // );

    const selected = localStorage.getItem("childUID");

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar>
        <RouterLink to="/dashboard">
          <img
            alt="Logo"
            //src="/images/logos/logo--white.svg"
            //src="/images/logos/logo.svg" height="50px" width="50px"
            src="/images/logos/logo-transparent1.png" height="50px" width="50px"
            // src="/images/logos/a.png" height="50px" 
          />
          {/* <img alt="Logo" src="/images/logos/meters_xpress.png" height="50px"  /> */}
          
        </RouterLink>

        <div className={classes.appbar}>
        <img alt="Logo" src="/images/logos/meters_xpress.png" height="50px"  className={classes.appbar} />
        </div>
        {/* <Typography className={classes.appbar}  variant="h4" noWrap>
            Meter Image Capturing
        </Typography> */}
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          {/*<IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>*/}
          <div style={{ display: isAdmin ? "block" : "none" }}>
          <Card>
          <FormControl  className={classes.formControl}>
                <Select
                    className={classes.selectEmpty}
                    // labelId="demo-simple-select-outlined-label"
                    // id="demo-simple-select-outlined"
                    value={selected}
                    onChange={handleCasinoChange}
                    label="Casino">
                    {/* <option value="" disabled>Select Casino</option> */}
                    {selectItems}
                </Select>
            </FormControl>
            </Card>
            </div>
            <div style={{ display: isAdmin ? "block" : "none" }}>
          <Tooltip title="You are signed in with a master account. You can manage linked accounts with the dropdown menu to the left.">
            <Chip className={classes.signOutButton} label="Admin" icon={<VerifiedUserIcon />} />
          </Tooltip>
          </div>
          <IconButton
            className={classes.signOutButton}
            color="inherit"
            onClick={handleSignOut}
          >
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
