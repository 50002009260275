import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import {
  MaterialTableDemo,
  DetailedAccordion
} from './components';
import firebase from '../../firebase/firebase';

const styles = theme => ({
  root: {
    padding: theme.spacing(2)
  },
});

class Dashboard extends Component {

  constructor(props) {
    super(props);
    if (!firebase.getCurrentUser()) {
      props.history.replace('/signin');
    }
    this.handleOnDateRangeSearchClick = this.handleOnDateRangeSearchClick.bind(this);
    this.handleOnDateClick = this.handleOnDateClick.bind(this);
    this.handleOnMachineIdSearch = this.handleOnMachineIdSearch.bind(this);
    this.state = { startDate: new Date(), endDate: new Date(), latestScansRange: '', machineId: null };
  }

  handleOnDateRangeSearchClick(startDate, endDate) {
    this.setState({ startDate: startDate, endDate: endDate });
  }

  // code is element of { hour, day, week } 
  handleOnDateClick(code) {
    this.setState({ latestScansRange: code });
  }

  handleOnMachineIdSearch(id) {
    this.setState({ machineId: id });
  }

  render() {
    const { classes } = this.props;
    const startDate = this.state.startDate;
    const endDate = this.state.endDate;
    const latestScansRange = this.state.latestScansRange;
    const machineId = this.state.machineId;
  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          lg={12}
          md={12}
          xl={9}
          xs={12}
        >
          <DetailedAccordion
            startDate={startDate}
            endDate={endDate}
            onDateRangeSearchClick={this.handleOnDateRangeSearchClick}
            onDateClick={this.handleOnDateClick}
            onMachineIdSearch={this.handleOnMachineIdSearch} />
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
        >
          <MaterialTableDemo
            startDate={startDate}
            endDate={endDate}
            latestScansRange={latestScansRange}
            machineId={machineId} />
        </Grid>
      </Grid>
    </div>
  );
      }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Dashboard);