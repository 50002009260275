import React, { Component } from 'react';
// import { makeStyles } from '@material-ui/styles';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import firebase from '../../firebase/firebase';
import { Notifications, Password } from './components';
// import InputLabel from '@material-ui/core/InputLabel';
// import MenuItem from '@material-ui/core/MenuItem';
// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
// import Typography from '@material-ui/core/Typography';
// import {
//     Card,
//     CardHeader,
//     CardContent,
//     CardActions,
//     Divider,
//     Button,
//     TextField
//   } from '@material-ui/core';

const styles = theme => ({
    root: {
    padding: theme.spacing(4)
    },
    item: {
    marginTop: theme.spacing(2)
    },
    formControl: {
    marginTop: theme.spacing(1),
    minWidth: 120
    }
});

class Settings extends Component {

    constructor(props) {
        super(props);
        this.handleCasinoChange = this.handleCasinoChange.bind(this);
        this.state = { isAdmin: false, children: [], casino: null };
    }

    async componentWillMount() {
        let isAdmin = await firebase.isAdminAccount();
        let children = await firebase.getChildAccounts();
        let selectedChild = localStorage.getItem("childUID");
        this.setState({ isAdmin: isAdmin, children: children, casino: selectedChild });
    }
//   const classes = useStyles();

//   if (!firebase.getCurrentUser()) {
//     props.history.replace('/signin');
//     return null;
//   }

//   const handleChange = (event) => {
//     const name = event.target.name;
//     setState({
//       ...state,
//       [name]: event.target.value,
//     });
//   };

handleCasinoChange(event) {
    // console.log(event.target.value);
    // this.setState({ casino: event.target.value });
    // localStorage.setItem("adminUID", "xgdRnVu3yrgjEhrMQgDSImBEOCc2");
    localStorage.setItem("childUID", event.target.value);
}

  render() {
    const { classes } = this.props;
    // const selectItems = this.state.children.map((child) => 
    //     <MenuItem value={child.uid}>{child.name}</MenuItem>
    // );
    // const selectedItem = localStorage.getItem("childUID");
  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}>
        <Grid
          item
          xs={12}
          //md={7}
          lg={7}
          xl={7}
          >
          <Notifications />
        </Grid>
        <Grid
          item
          xs={12}
          //md={5}
          lg={5}
          xl={5}
          >
          <Password />
          {/* <div style={{ display: this.state.isAdmin ? "block" : "none" }} className={classes.item}>
          <Card>
          <CardHeader
          title="Admin Mode"
        />
        <Divider />
        <CardContent>
            <FormControl variant="outlined" className={classes.formControl}>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    // value={this.state.casino}
                    onChange={this.handleCasinoChange}
                    label="Casino">
                    {selectItems}
                </Select>
            </FormControl>
            </CardContent>
            </Card>
          </div> */}
        </Grid>
      </Grid>
    </div>
  );
  }
};

Settings.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Settings);